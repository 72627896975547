import styled from 'styled-components'
//material ui
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

export const Logo = styled.img`
  margin: 0 25px;
  max-height: 50px;
`

export const NavbarWrapper = styled.header`
  background: #263C59;
  margin-bottom: 1.45rem;
  transition: 0.3s;

  div.collapse {
    display: none !important;
  }

  div.show {
    display: block !important;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 770px) {
    display: none;
  }
`

export const MobileMenu = styled(IconButton)`
  display: none !important;
  font-size: 2.2rem;

  @media (max-width: 770px) {
    display: flex !important;
  }
`

export const MobileMenuIcon = styled(MenuIcon)`
  fill: #375781 !important;
  font-size: 2rem !important;
`

export const NavLink = styled.div`
  margin: 0 12px;
  height: 60px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0;
  display: flex;
  align-items: center;

  &:hover {
    top: -4px;
    left: -4px;
    box-shadow: 4px 4px rgba(0, 0, 0, 0.1);
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 770px) {
    margin: 10px 20px;
    max-width: 120px;
  }
`

export const MobileLinks = styled.div`
  display: none !important;

  @media (max-width: 770px) {
    display: block !important;
  }
`

export const SubscribeButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #a6bcd9;
  color: #111a27;
  min-width: 64px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  height: 35px;

  @media (max-width: 770px) {
    margin: 0px 0px 20px 35px;
  }
`