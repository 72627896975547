import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FooterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-left: 40px;

    @media (max-width: 600px) {
      padding-left: 0px;
      text-align: center;
      margin: 0 auto;
      justify-content: center !important;
    }
`

const FooterInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding-left: 40px;

  @media (max-width: 600px) {
      display: block !important;
      padding-left: 0px;
  }
`

const MenuItem = styled.div`
    flex: 0 0 25%;
    max-width: 25%;

    a {
      color: white;
      text-decoration: none;
    }

    ul {
      display: block;
      list-style-type: none;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-inline-start: 0px;
    }

    li {
        list-style: none;
    }

    @media (max-width: 600px) {
      margin: 0 auto;
  }
`

const MenuTitle = styled.p`
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .25em;
`

const Footer = () => (
    <>
      <FooterWrapper>
          <FooterInnerWrapper>
            <MenuItem>
              <MenuTitle>Origin Audio</MenuTitle>
              <ul>
              <a href='https://www.originaudio.net/services'><li>Services</li></a>
              </ul>
            </MenuItem>
            <MenuItem>
              <MenuTitle>Software</MenuTitle>
              <ul>
                <a href='https://www.originaudio.net/software/midireplay'><li>MidiReplay</li></a>
              </ul>
            </MenuItem>
            <MenuItem>
              <MenuTitle>Legal</MenuTitle>
              <ul>
                <li>
                  <a href="https://oapublicstorage.blob.core.windows.net/documents/Privacy%20Policy.pdf" rel="noreferrer" target="_blank">Privacy Policy
                  </a>
                </li>
              </ul>
            </MenuItem>
            <MenuItem>
              <MenuTitle>Contact</MenuTitle>
              {/* <ul>
                <li>
                  <a><i class="fas fa-envelope-square fa-3x icons pr-2"></i></a>
                  <a href="https://www.instagram.com/origin_audio_llc/" target="_blank"><i
                    class="fab fa-instagram fa-3x icons pr-2"></i></a>
                    <a href="https://discord.gg/tEFparsvJ4" target="_blank"><i class="fab fa-discord fa-3x icons pr-2"></i></a>
                    <a href="https://www.linkedin.com/company/origin-audio/" target="_blank"><i
                        class="fab fa-linkedin fa-3x icons pr-2"></i></a>
                  <a href="https://github.com/OriginAudio" target="_blank"><i class="fab fa-github-square fa-3x icons pr-2"></i></a>
                </li>
              </ul> */}
            </MenuItem>
          </FooterInnerWrapper>
      </FooterWrapper>
  </>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
