import React, {useState} from 'react'
import { Link } from 'gatsby'
import { OA_text_logo } from '../../images'
//styles
import {
    NavbarWrapper, 
    NavLink, 
    LinkWrapper,
    SubscribeButton,
    Logo, 
    MobileMenu, 
    MobileLinks,
    MobileMenuIcon
} from './styles'
//bootstrap
import {Collapse} from 'react-bootstrap'

export default function Navbar() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <NavbarWrapper>
                <div
                    style={{
                        padding: '3px 10px',
                        margin: `0 auto`,
                        height: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <a href='https://www.originaudio.net'>
                            <Logo src={OA_text_logo} />
                        </a>
                    </div>
                    <LinkWrapper>
                        <div>
                        <NavLink><a href='https://www.originaudio.net/services'>Services</a></NavLink>
                        </div>
                        <div>
                        <NavLink><a href='https://www.originaudio.net/software/midireplay'>Midi Replay</a></NavLink>
                        </div>
                        <SubscribeButton>Sign Up!</SubscribeButton>
                    </LinkWrapper>

                    <MobileMenu
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        <MobileMenuIcon />
                    </MobileMenu>

                </div>

                <MobileLinks>
                    <Collapse in={open}>
                        <div>
                            <NavLink><a href='https://www.originaudio.net/services'>Services</a></NavLink>
                            <NavLink><a href='https://www.originaudio.net/software/midireplay'>Midi Replay</a></NavLink>
                            <SubscribeButton>Sign Up!</SubscribeButton>
                        </div>
                    </Collapse>
                </MobileLinks>
                
            </NavbarWrapper>
        </>
    )
}
    


